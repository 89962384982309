import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import Logo from "../../images/logo.png";
import SideImg from "../../images/login-image.svg";
import { API_Path } from "../../const";
import { PostApi } from "../../helper/APIService";

const sign = require("jwt-encode");
const secret = "^HkNZ*AqT$1Nyi_1zVf)pzp0i7y6kz#Uf4%sxs!s4Ae&G5u";

export default function Login() {
	const navigate = useNavigate();
	const [remember, setremember] = useState(false);
	const [email, setemail] = useState("");
	const [password, setpassword] = useState("");
	const [showPassword, setshowPassword] = useState("password");
	const [IconPassword, setIconPassword] = useState("bi-eye-slash");

	useEffect(() => {
		let remember_me_token = localStorage.getItem("remember_token");
		if (remember_me_token !== null) {
			let temp = jwt_decode(remember_me_token);
			setremember(true);
			setemail(temp.email);
			setpassword(temp.password);
		}
	}, []);

	const goTo = (path) => {
		navigate(path);
	};

	const encode_token_jwt = (formData) => {
		var date = new Date();
		var time = date.getTime();
		const data = { exp: time + 3600, email: formData.email, password: formData.password, iat: time };
		const jwt = sign(data, secret);
		if (remember) {
			localStorage.setItem("remember_token", jwt);
		}
	};

	const submitFormData = (formData, resetForm) => {
		let data = { email: formData.email, password: formData.password };
		const LoginDataPromise = new Promise((resolve, reject) => {
			resolve(PostApi(API_Path.login, data));
		});
		LoginDataPromise.then((response) => {
			if (response.status === 200) {
				toast.success(response.data.message);
				resetForm(formData);
				localStorage.setItem("smart_internal_token", "Bearer " + response.data.token);
				localStorage.setItem("login", JSON.stringify(response.data.data));
				encode_token_jwt(formData);
				response.data.data.role_id === 1 ? navigate("/dashboard") : navigate("/store");
			} else {
				toast.error(response.data.message);
			}
		});
	};

	const errorContainer = (form, field) => {
		return form.touched[field] && form.errors[field] ? <span className="error text-danger">{form.errors[field]}</span> : null;
	};

	const formAttr = (form, field) => ({
		onBlur: form.handleBlur,
		onChange: form.handleChange,
		value: form.values[field],
	});

	const showpassword = () => {
		if (showPassword === "password") {
			setshowPassword("text");
			setIconPassword("bi-eye");
		} else {
			setshowPassword("password");
			setIconPassword("bi-eye-slash");
		}
	};

	const handelCheckbox = (e) => {
		if (e.target.checked) {
			setremember(true);
		} else {
			setremember(false);
			localStorage.removeItem("remember_token");
		}
	};

	const handelSetEmail = (e) => {
		setemail(e.target.value);
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-xl-4 col-lg-5 p-0 d-lg-block d-none">
					<div className="login-side-img">
						<img src={SideImg} alt="login" className="img-fluid" />
					</div>
				</div>
				<div className="col-xl-8 col-lg-7 login-form-side">
					<div className="login-main-area">
						<div className="login-main-part-scroll">
							<div className="login-main-side">
								<div className="m-auto login-main-box user-sign-form white-bx-info">
									<div className="w-100 text-center mb-4">
										<img src={Logo} alt="logo" className="img-fluid" />
									</div>
									<div className="text-center login-text-top pb-2">
										<h1>Sign In</h1>
										<p>Provide your sign in details to access your account</p>
									</div>
									<Formik
										enableReinitialize
										initialValues={{
											email: email ? email : "",
											password: password ? password : "",
										}}
										validationSchema={Yup.object({
											email: Yup.string().email("Enter valid Email address.").required("Email is required."),
											password: Yup.string().required("Password is required."),
										})}
										onSubmit={(formData, { resetForm }) => {
											submitFormData(formData, resetForm);
										}}
									>
										{(runform) => (
											<form className="row" onSubmit={runform.handleSubmit}>
												<div className="col-12 mb-3">
													<label className="form-lbl-class mb-2">Email Address</label>
													<input type="email" {...formAttr(runform, "email")} onChangeCapture={handelSetEmail} className="form-control style-input-class" name="email" placeholder="Johndoe@gmail.com" />
													{errorContainer(runform, "email")}
												</div>
												<div className="col-12 mb-3">
													<label className="form-lbl-class mb-2">Password</label>
													<bdi className="d-block  password-class">
														<div className="position-relative">
															<input id="toggle-pass" type={showPassword} {...formAttr(runform, "password")} className="form-control style-input-class" name="password" placeholder="Enter your new password" />
															<span id="eye-pwd" className="eye-pwd bg-transparent" onClick={showpassword}>
																<i className={"bi " + IconPassword} />
															</span>
														</div>
													</bdi>
													{errorContainer(runform, "password")}
												</div>
												<div className="col-6 mb-3">
													<div className="custom-checkbox">
														<label className="custom-lbl-part" htmlFor="rememberme">
															<input type="checkbox" id="rememberme" checked={remember} onChange={handelCheckbox} />
															<span className="custom-checkbox-class"></span>
															Remember Me
														</label>
													</div>
												</div>
												<div className="col-6 mb-3 text-end">
													<div className="d-inline-block form-link-style">
														<span onClick={() => goTo("/forgot-password")}>Forgot Password ?</span>
													</div>
												</div>
												<div className="col-12 mt-3 text-center">
													<button type="submit" className="btn-smart-comn w-100">
														Sign In
													</button>
												</div>
											</form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
