import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ArrowCheck from "../../images/arrow-check-icon.svg";
import ArrowCheckRemove from "../../images/arrow-check-close.svg";
import LogoShort from "../../images/logo-short.png";

function Sidebar(props) {
	const Navigate = useNavigate();
	const location = useLocation();
	const [role, setRole] = useState("");

	useEffect(() => {
		let login_data = JSON.parse(localStorage.getItem("login"));
		setRole(login_data.role_id);
	}, []);

	const handleLogout = () => {
		localStorage.removeItem("smart_internal_token");
		localStorage.removeItem("login");
		Navigate("/login");
	};

	const idarrowdesk = () => {
		document.getElementById("root").classList.add("dash-main-class-add");
	};

	const idarrowdeskremove = () => {
		document.getElementById("root").classList.remove("dash-main-class-add");
	};

	return (
		<React.Fragment>
			<div className="sidebar-main-section">
				<div className="brand-title d-flex align-items-center">
					<div className="d-inline-flex align-items-center">
						<a href="/" className="d-inline-flex align-items-center cursor-pointer">
							<div className="d-inline-flex align-items-center">
								<img src={LogoShort} alt="logo" />
								<span>Smartscrapers</span>
							</div>
						</a>
					</div>
					<div className="ms-auto position-relative ps-2 d-xl-block d-none">
						<img src={ArrowCheck} className="close-arrow-show" alt="arrow" onClick={() => idarrowdesk()} id="idarrowdesk" />
						<img src={ArrowCheckRemove} className="close-arrow-hide" onClick={() => idarrowdeskremove()} id="idarrowdeskremove" alt="arrow" />
					</div>
				</div>

				<div className="sidebar-main-section-inner">
					<ul className="sidebar-menu-boxes">
						<li>
							<Link to="/dashboard" className={location.pathname === "/dashboard" ? "active" : ""}>
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M15.8334 18.3333H4.16669C3.70645 18.3333 3.33335 17.9602 3.33335 17.5V10.8333H1.66669L9.41002 3.08917C9.56633 2.93269 9.77843 2.84476 9.9996 2.84476C10.2208 2.84476 10.4329 2.93269 10.5892 3.08917L18.3334 10.8333H16.6667V17.5C16.6667 17.9602 16.2936 18.3333 15.8334 18.3333ZM8.33335 12.5H11.6667V16.6667H15V9.85667L10 4.85667L5.00002 9.85667V16.6667H8.33335V12.5Z" fill="#6C6A81" />
									<defs></defs>
								</svg>
								<span>Dashboard</span>
							</Link>
						</li>
						{role == 2 && (
							<li>
								<Link to="/store" className={location.pathname === "/store" ? "active" : ""}>
									<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M15.9567 2.47583C15.8082 2.2296 15.5988 2.02579 15.3486 1.88406C15.0984 1.74233 14.8159 1.66746 14.5284 1.66667H5.47169C4.89002 1.66667 4.34252 1.97667 4.04335 2.47583L1.78585 6.2375C1.70773 6.36701 1.66652 6.51542 1.66669 6.66667C1.67002 7.47101 1.96627 8.24659 2.50002 8.84833V15.8333C2.50002 16.7525 3.24752 17.5 4.16669 17.5H15.8334C16.7525 17.5 17.5 16.7525 17.5 15.8333V8.84833C18.0338 8.24659 18.33 7.47101 18.3334 6.66667C18.3335 6.51542 18.2923 6.36701 18.2142 6.2375L15.9567 2.47583ZM16.6534 6.87583C16.6019 7.27818 16.4057 7.64804 16.1015 7.91627C15.7972 8.1845 15.4056 8.33276 15 8.33333C14.0809 8.33333 13.3334 7.58583 13.3334 6.66667C13.3334 6.61 13.3125 6.56 13.3009 6.50667L13.3175 6.50333L12.6834 3.33333H14.5284L16.6534 6.87583ZM9.01585 3.33333H10.9834L11.6609 6.72083C11.6317 7.61417 10.9 8.33333 10 8.33333C9.10002 8.33333 8.36835 7.61417 8.33919 6.72083L9.01585 3.33333ZM5.47169 3.33333H7.31669L6.68335 6.50333L6.70002 6.50667C6.68752 6.56 6.66669 6.61 6.66669 6.66667C6.66669 7.58583 5.91919 8.33333 5.00002 8.33333C4.5944 8.33276 4.20286 8.1845 3.89859 7.91627C3.59431 7.64804 3.39812 7.27818 3.34669 6.87583L5.47169 3.33333ZM8.33335 15.8333V13.3333H11.6667V15.8333H8.33335ZM13.3334 15.8333V13.3333C13.3334 12.4142 12.5859 11.6667 11.6667 11.6667H8.33335C7.41419 11.6667 6.66669 12.4142 6.66669 13.3333V15.8333H4.16669V9.88167C4.43419 9.95083 4.71002 10 5.00002 10C5.4732 10.0002 5.94098 9.8995 6.37211 9.70449C6.80323 9.50948 7.18777 9.22469 7.50002 8.86917C8.11085 9.5625 9.00585 10 10 10C10.9942 10 11.8892 9.5625 12.5 8.86917C12.8123 9.22469 13.1968 9.50948 13.6279 9.70449C14.0591 9.8995 14.5268 10.0002 15 10C15.29 10 15.5659 9.95083 15.8334 9.88167V15.8333H13.3334Z" fill="#6C6A81" />
									</svg>
									<span>store</span>
								</Link>
							</li>
						)}
						{role == 1 && (
							<li>
								<Link to="/user" className={location.pathname === "/user" ? "active" : ""}>
									<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M5.83331 6.66667C5.83331 5.5616 6.2723 4.50179 7.0537 3.72039C7.8351 2.93899 8.89491 2.5 9.99998 2.5C11.105 2.5 12.1649 2.93899 12.9463 3.72039C13.7277 4.50179 14.1666 5.5616 14.1666 6.66667C14.1666 7.77174 13.7277 8.83154 12.9463 9.61294C12.1649 10.3943 11.105 10.8333 9.99998 10.8333C8.89491 10.8333 7.8351 10.3943 7.0537 9.61294C6.2723 8.83154 5.83331 7.77174 5.83331 6.66667ZM9.99998 9.16667C10.663 9.16667 11.2989 8.90327 11.7677 8.43443C12.2366 7.96559 12.5 7.32971 12.5 6.66667C12.5 6.00363 12.2366 5.36774 11.7677 4.8989C11.2989 4.43006 10.663 4.16667 9.99998 4.16667C9.33694 4.16667 8.70105 4.43006 8.23221 4.8989C7.76337 5.36774 7.49998 6.00363 7.49998 6.66667C7.49998 7.32971 7.76337 7.96559 8.23221 8.43443C8.70105 8.90327 9.33694 9.16667 9.99998 9.16667ZM5.28581 13.6192C4.66676 14.2382 4.17571 14.9732 3.8407 15.7821C3.50569 16.5909 3.33328 17.4578 3.33331 18.3333H4.99998C4.99998 17.0073 5.52676 15.7355 6.46445 14.7978C7.40213 13.8601 8.6739 13.3333 9.99998 13.3333C11.3261 13.3333 12.5978 13.8601 13.5355 14.7978C14.4732 15.7355 15 17.0073 15 18.3333H16.6666C16.6667 17.0148 16.2757 15.7258 15.5432 14.6294C14.8106 13.533 13.7694 12.6785 12.5512 12.1739C11.333 11.6693 9.99255 11.5373 8.69931 11.7945C7.40607 12.0518 6.21816 12.6868 5.28581 13.6192Z" fill="#6C6A81" />
									</svg>
									<span>User</span>
								</Link>
							</li>
						)}
						{role == 1 && (
							<li>
								<Link to="/user-reports" className={location.pathname === "/user-reports" ? "active" : ""}>
									<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M5.83331 6.66667C5.83331 5.5616 6.2723 4.50179 7.0537 3.72039C7.8351 2.93899 8.89491 2.5 9.99998 2.5C11.105 2.5 12.1649 2.93899 12.9463 3.72039C13.7277 4.50179 14.1666 5.5616 14.1666 6.66667C14.1666 7.77174 13.7277 8.83154 12.9463 9.61294C12.1649 10.3943 11.105 10.8333 9.99998 10.8333C8.89491 10.8333 7.8351 10.3943 7.0537 9.61294C6.2723 8.83154 5.83331 7.77174 5.83331 6.66667ZM9.99998 9.16667C10.663 9.16667 11.2989 8.90327 11.7677 8.43443C12.2366 7.96559 12.5 7.32971 12.5 6.66667C12.5 6.00363 12.2366 5.36774 11.7677 4.8989C11.2989 4.43006 10.663 4.16667 9.99998 4.16667C9.33694 4.16667 8.70105 4.43006 8.23221 4.8989C7.76337 5.36774 7.49998 6.00363 7.49998 6.66667C7.49998 7.32971 7.76337 7.96559 8.23221 8.43443C8.70105 8.90327 9.33694 9.16667 9.99998 9.16667ZM5.28581 13.6192C4.66676 14.2382 4.17571 14.9732 3.8407 15.7821C3.50569 16.5909 3.33328 17.4578 3.33331 18.3333H4.99998C4.99998 17.0073 5.52676 15.7355 6.46445 14.7978C7.40213 13.8601 8.6739 13.3333 9.99998 13.3333C11.3261 13.3333 12.5978 13.8601 13.5355 14.7978C14.4732 15.7355 15 17.0073 15 18.3333H16.6666C16.6667 17.0148 16.2757 15.7258 15.5432 14.6294C14.8106 13.533 13.7694 12.6785 12.5512 12.1739C11.333 11.6693 9.99255 11.5373 8.69931 11.7945C7.40607 12.0518 6.21816 12.6868 5.28581 13.6192Z" fill="#6C6A81" />
									</svg>
									<span>User Reports</span>
								</Link>
							</li>
						)}
						{role == 1 && (
							<li>
								<Link to="/task-assign" className={location.pathname === "/task-assign" ? "active" : ""}>
									<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M5.83331 6.66667C5.83331 5.5616 6.2723 4.50179 7.0537 3.72039C7.8351 2.93899 8.89491 2.5 9.99998 2.5C11.105 2.5 12.1649 2.93899 12.9463 3.72039C13.7277 4.50179 14.1666 5.5616 14.1666 6.66667C14.1666 7.77174 13.7277 8.83154 12.9463 9.61294C12.1649 10.3943 11.105 10.8333 9.99998 10.8333C8.89491 10.8333 7.8351 10.3943 7.0537 9.61294C6.2723 8.83154 5.83331 7.77174 5.83331 6.66667ZM9.99998 9.16667C10.663 9.16667 11.2989 8.90327 11.7677 8.43443C12.2366 7.96559 12.5 7.32971 12.5 6.66667C12.5 6.00363 12.2366 5.36774 11.7677 4.8989C11.2989 4.43006 10.663 4.16667 9.99998 4.16667C9.33694 4.16667 8.70105 4.43006 8.23221 4.8989C7.76337 5.36774 7.49998 6.00363 7.49998 6.66667C7.49998 7.32971 7.76337 7.96559 8.23221 8.43443C8.70105 8.90327 9.33694 9.16667 9.99998 9.16667ZM5.28581 13.6192C4.66676 14.2382 4.17571 14.9732 3.8407 15.7821C3.50569 16.5909 3.33328 17.4578 3.33331 18.3333H4.99998C4.99998 17.0073 5.52676 15.7355 6.46445 14.7978C7.40213 13.8601 8.6739 13.3333 9.99998 13.3333C11.3261 13.3333 12.5978 13.8601 13.5355 14.7978C14.4732 15.7355 15 17.0073 15 18.3333H16.6666C16.6667 17.0148 16.2757 15.7258 15.5432 14.6294C14.8106 13.533 13.7694 12.6785 12.5512 12.1739C11.333 11.6693 9.99255 11.5373 8.69931 11.7945C7.40607 12.0518 6.21816 12.6868 5.28581 13.6192Z" fill="#6C6A81" />
									</svg>
									<span>Task Assign</span>
								</Link>
							</li>
						)}
						{role == 2 && (
							<li>
								<Link to="/daily-reports" className={location.pathname === "/daily-reports" ? "active" : ""}>
									<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M5.83331 6.66667C5.83331 5.5616 6.2723 4.50179 7.0537 3.72039C7.8351 2.93899 8.89491 2.5 9.99998 2.5C11.105 2.5 12.1649 2.93899 12.9463 3.72039C13.7277 4.50179 14.1666 5.5616 14.1666 6.66667C14.1666 7.77174 13.7277 8.83154 12.9463 9.61294C12.1649 10.3943 11.105 10.8333 9.99998 10.8333C8.89491 10.8333 7.8351 10.3943 7.0537 9.61294C6.2723 8.83154 5.83331 7.77174 5.83331 6.66667ZM9.99998 9.16667C10.663 9.16667 11.2989 8.90327 11.7677 8.43443C12.2366 7.96559 12.5 7.32971 12.5 6.66667C12.5 6.00363 12.2366 5.36774 11.7677 4.8989C11.2989 4.43006 10.663 4.16667 9.99998 4.16667C9.33694 4.16667 8.70105 4.43006 8.23221 4.8989C7.76337 5.36774 7.49998 6.00363 7.49998 6.66667C7.49998 7.32971 7.76337 7.96559 8.23221 8.43443C8.70105 8.90327 9.33694 9.16667 9.99998 9.16667ZM5.28581 13.6192C4.66676 14.2382 4.17571 14.9732 3.8407 15.7821C3.50569 16.5909 3.33328 17.4578 3.33331 18.3333H4.99998C4.99998 17.0073 5.52676 15.7355 6.46445 14.7978C7.40213 13.8601 8.6739 13.3333 9.99998 13.3333C11.3261 13.3333 12.5978 13.8601 13.5355 14.7978C14.4732 15.7355 15 17.0073 15 18.3333H16.6666C16.6667 17.0148 16.2757 15.7258 15.5432 14.6294C14.8106 13.533 13.7694 12.6785 12.5512 12.1739C11.333 11.6693 9.99255 11.5373 8.69931 11.7945C7.40607 12.0518 6.21816 12.6868 5.28581 13.6192Z" fill="#6C6A81" />
									</svg>
									<span>Daily Report</span>
								</Link>
							</li>
						)}
					</ul>
					<div className="sidebar-log-fix">
						<button type="submit" className="border-0 bg-transparent" onClick={() => handleLogout()}>
							<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M19.5558 0H2.44421C1.79597 0 1.17427 0.257515 0.715894 0.715894C0.257515 1.17427 0 1.79597 0 2.44421L0 7.33368H2.44421V2.44421H19.5558V19.5558H2.44421V14.6674H0V19.5558C0 20.204 0.257515 20.8257 0.715894 21.2841C1.17427 21.7425 1.79597 22 2.44421 22H19.5558C20.204 22 20.8257 21.7425 21.2841 21.2841C21.7425 20.8257 22 20.204 22 19.5558V2.44421C22 1.79597 21.7425 1.17427 21.2841 0.715894C20.8257 0.257515 20.204 0 19.5558 0V0Z" fill="#6C6A81"></path>
								<path d="M8.65974 15.3823L10.3892 17.1118L16.5003 11.0007L10.3892 4.88965L8.65974 6.61911L11.8187 9.77911H0V12.2233H11.8187L8.65974 15.3823Z" fill="#6C6A81"></path>
							</svg>
							<span>Log Out</span>
						</button>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default Sidebar;
