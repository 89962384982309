import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SideImg from "../../images/forgot-image.svg";
import Logo from "../../images/logo.png";
import { PostApi } from "../../helper/APIService";
import { API_Path } from "../../const";

export default function ForgotPassword() {
    const navigate = useNavigate();

    const submitFormData = (formData, resetForm) => {
        const ForgotPassword = new Promise((resolve) => {
            resolve(PostApi(API_Path.forgotPassword, { email: formData.email }));
        });
        ForgotPassword.then((res) => {
            if (res.status === 200) {
                toast.success(res.data.message);
                resetForm(formData);
                // navigate("/verification", { state: { email: formData.email } });
                navigate('/login')
            } else {
                toast.error(res.data.message);
            }
        });
    };

    const errorContainer = (form, field) => {
        return form.touched[field] && form.errors[field] ? <span className="error text-danger">{form.errors[field]}</span> : null;
    };

    const formAttr = (form, field) => ({
        onBlur: form.handleBlur,
        onChange: form.handleChange,
        value: form.values[field],
    });

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-xl-4 col-lg-5 p-0 d-lg-block d-none">
                    <div className="login-side-img">
                        <img src={SideImg} alt="" className="img-fluid" />
                    </div>
                </div>
                <div className="col-xl-8 col-lg-7 login-form-side">
                    <div className="login-main-area">
                        <div className="login-main-part-scroll">
                            <div className="login-main-side">
                                <div className="m-auto login-main-box user-sign-form white-bx-info  ">
                                    <div className="w-100 text-center mb-4">
                                        <img src={Logo} alt="" className="img-fluid" />
                                    </div>
                                    <div className="text-center login-text-top pb-2">
                                        <h1>Forgot Password</h1>
                                        <p>Enter your registered Email ID below, we will send you verification code to verify you email.</p>
                                    </div>
                                    <Formik
                                        enableReinitialize
                                        initialValues={{
                                            email: "",
                                        }}
                                        validationSchema={Yup.object({
                                            email: Yup.string().email("Enter valid Email address.").required("Email is required."),
                                        })}
                                        onSubmit={(formData, { resetForm }) => {
                                            submitFormData(formData, resetForm);
                                        }}
                                    >
                                        {(runform) => (
                                            <form className="row" onSubmit={runform.handleSubmit}>
                                                <div className="col-12 mb-3">
                                                    <label className="form-lbl-class mb-2">Email Address</label>
                                                    <input type="email" {...formAttr(runform, "email")} className="form-control style-input-class" name="email" placeholder="Johndoe@gmail.com" />
                                                    {errorContainer(runform, "email")}
                                                </div>
                                                <div className="col-12 mt-3 text-center">
                                                    <button type="submit" className="btn-smart-comn w-100 w-100">
                                                        send
                                                    </button>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                            <div className="no-act-link text-center">
                                Remember password?
                                <a href="/login"> Sign in</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
