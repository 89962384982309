const axios = require("axios");

export function GetApi(path, token_Data) {
	let token = token_Data;
	if (!token || token == "") {
		try {
			token = localStorage.getItem("smart_internal_token");
		} catch (e) {}
	}
	let headers = { Authorization: token, "Content-Type": "application/json" };
	const GetApiData = axios
		.get(path, { headers: headers })
		.then((response) => {
			return response;
		})
		.catch((err) => {
			if (err.response.data.message === "Not Authorized or invalid token.") {
				localStorage.removeItem("smart_internal_token");
				window.location.href = "/login";
			}
			return err.response;
		});
	return GetApiData;
}
export function PostApi(path, body, token_Data) {
	let token = token_Data;
	if (!token || token == "") {
		try {
			token = localStorage.getItem("smart_internal_token");
		} catch (e) {}
	}
	let headers = { Authorization: token, "Content-Type": "application/json" };
	const PostApiData = axios
		.post(path, body, { headers: headers })
		.then((response) => {
			return response;
		})
		.catch((err) => {
			if (err.response.data.message === "Not Authorized or invalid token.") {
				localStorage.removeItem("smart_internal_token");
				window.location.href = "/login";
			}
			return err.response;
		});
	return PostApiData;
}
