import React, { useEffect, useState } from "react";
import ArrowCheck from "../../images/arrow-check-icon.svg";
import ArrowCheckRemove from "../../images/arrow-check-close.svg";

function Header(props) {
	const [role, setRole] = useState(1);
	const [name, setName] = useState("");

	useEffect(() => {
		let data = JSON.parse(localStorage.getItem("login"));
		setRole(data.role_id);
		setName(data.name);
	}, []);

	const idarrowdesk = () => {
		document.getElementById("root").classList.add("dash-main-class-add");
	};

	const idarrowdeskremove = () => {
		document.getElementById("root").classList.remove("dash-main-class-add");
	};

	return (
		<React.Fragment>
			<header className="header-fix-top-section">
				<div className="d-xl-none pe-3">
					<img src={ArrowCheck} className="close-arrow-show" alt="arrow" onClick={() => idarrowdesk()} id="idarrowdesk" />
					<img src={ArrowCheckRemove} className="close-arrow-hide" onClick={() => idarrowdeskremove()} id="idarrowdeskremove" alt="arrow" />
				</div>
				<div className="main-title-dash">
					<h1>{props.PageDetails}</h1>
				</div>
				<div className="ms-auto text-end overflow-hidden ps-3">
					<div className="d-inline-flex align-items-center prf-hdr-top align-middle overflow-hidden ms-auto w-100">
						<div className="align-middle position-relative d-inline-flex me-4">
							<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M8 20C9.1 20 10 19.1 10 18H6C6 19.1 6.9 20 8 20ZM14 14V9C14 5.93 12.37 3.36 9.5 2.68V2C9.5 1.17 8.83 0.5 8 0.5C7.17 0.5 6.5 1.17 6.5 2V2.68C3.64 3.36 2 5.92 2 9V14L0 16V17H16V16L14 14ZM12 15H4V9C4 6.52 5.51 4.5 8 4.5C10.49 4.5 12 6.52 12 9V15Z" fill="#6C6A81" />
							</svg>
							<span className="count-number-top">4</span>
						</div>
						<div className="pe-2 text-end overflow-hidden w-100">
							<span className="d-block">{name && name}</span>
							<bdi className="d-block">{role === 1 ? "Admin" : "User"}</bdi>
						</div>
						{/* <img src={Profile} alt="profile" /> */}
						<div>
							<i className="bi bi-person-fill"></i>
						</div>
					</div>
				</div>
			</header>
		</React.Fragment>
	);
}

export default Header;
