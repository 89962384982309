import React from "react";
import FrontendLayout from "../Components/Layout/FrontendLayout";

function Dashboard() {
    return (
        <FrontendLayout PageDetails="dashboard">
            <div className="content-main-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">Dashboard</div>
                    </div>
                </div>
            </div>
        </FrontendLayout>
    );
}

export default Dashboard;
