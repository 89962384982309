import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { Modal } from "react-bootstrap";
import FrontendLayout from "../Components/Layout/FrontendLayout";
import RtdDatatable from "../Components/DataTable/RtdDatatable";
import { PostApi } from "../helper/APIService";
import { API_Path } from "../const";
import { toast } from "react-toastify";
import Lottie from "react-lottie-player";
import loder_data from "../Components/loader/data.json";
import { Formik } from "formik";
import * as Yup from "yup";

export default function DailyReports() {
	const runforms = useRef();
	const [data, setData] = useState([]);
	const [loader, set_loader] = useState(false);

	useEffect(() => {
		getMyReport();
	}, []);

	const getMyReport = () => {
		set_loader(true);
		const myReportdata = new Promise((resolve, reject) => {
			resolve(PostApi(API_Path.getUserTaskDetails));
		});
		myReportdata.then((response) => {
			set_loader(false);
			if (response.status === 200) {
				console.log(response.data);
				setData(response.data.data);
				toast.success(response.data.message);
			} else {
				toast.error(response.data.message);
			}
		});
	};
	return (
		<>
			{loader && (
				<div className="loader loader-dateset-main">
					<Lottie loop animationData={loder_data} play />
				</div>
			)}
			<FrontendLayout PageDetails="store">
				<div className="content-main-section">
					<div className="container-fluid">
						<div className="row">
							<div className="col-6">
								<div className="comn-inr-title">
									<h2>Date wise Report List</h2>
								</div>
							</div>
							<div className="col-12">
								<div className="table-custom-info">
									<table className="table">
										<thead>
											<th>Date</th>
											<th>Contact Us</th>
											<th>Live Chat</th>
											<th>Instgaram</th>
											<th>Facebook</th>
											<th>Pintrest</th>
											<th>Tiktok</th>
											<th>LinkedIn</th>
											<th>Twitter</th>
											<th>Snapchat</th>
											<th>Total</th>
										</thead>
										<tbody>
											<tr>
												<td>25-10-2022</td>
												<td>25</td>
												<td>30</td>
												<td>35</td>
												<td>40</td>
												<td>45</td>
												<td>50</td>
												<td>55</td>
												<td>60</td>
												<td>65</td>
												<td>70</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</FrontendLayout>
		</>
	);
}
