import React, { Component } from "react";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../src/Components/style.scss";
import RoutesMain from "./RoutesMain";
import { ToastContainer } from "react-toastify";

class App extends Component {
	render() {
		return (
			<React.Fragment>
				<ToastContainer autoClose={1000} theme="dark" position="bottom-left" />
				<RoutesMain />
			</React.Fragment>
		);
	}
}

export default App;
