export const APIBaseUrl = Number(process.env.REACT_APP_PRODUCTION) == 0 ? process.env.REACT_APP_LOCAL_BASE_URL : process.env.REACT_APP_LIVE_BASE_URL;

export const API_Path = {
	login: APIBaseUrl + "auth/login",
	createPassword: APIBaseUrl + "auth/create-password",
	forgotPassword: APIBaseUrl + "auth/forgot-password",
	createUser: APIBaseUrl + "auth/create-user",

	getAllactivities: APIBaseUrl + "common/attribute/get-activities",

	getAllUser: APIBaseUrl + "admin/user/get-all-user",
	getUser: APIBaseUrl + "admin/user/get-user",
	assignTaskActivity: APIBaseUrl + "admin/user/assign-task-activity",

	getUserTask: APIBaseUrl + "user/task/get-user-task",
	accomplishIserTask: APIBaseUrl + "user/task/accomplish-user-task",
	getDailyTotalTask: APIBaseUrl + "user/task/task-detail",
	getUserTaskDetails: APIBaseUrl + "user/task/task-detail",
};
