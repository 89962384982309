import React, { useEffect, useState } from "react";
import FrontendLayout from "../Components/Layout/FrontendLayout";
import { Dropdown, Modal } from "react-bootstrap";
import RtdDatatable from "../Components/DataTable/RtdDatatable";
import { Formik } from "formik";
import * as Yup from "yup";
import { GetApi, PostApi } from "../helper/APIService";
import { API_Path } from "../const";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie-player";
import loder_data from "../Components/loader/data.json";

export default function User() {
	const navigate = useNavigate();
	const [show, setShow] = useState(false);
	const [loader, set_loader] = useState(false);
	const [data, setData] = useState([]);
	const [columns, setColumns] = useState([
		{
			value: "name",
			label: "Name",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			value: "email",
			label: "Email",
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			value: "mobile",
			label: "Mobile Number",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			value: "location",
			label: "Location",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (data, i) => {
					return <span className="fix-size-td">7950 Virginia Oaks, Gainesville, Vermont, USA.</span>;
				},
			},
		},
		{
			value: "status",
			label: "Status",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (data, i) => {
					return <span className="user-status active">Active</span>;
				},
			},
		},
		{
			value: "action",
			label: "Action",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (data, i) => {
					return (
						<div className="cust-drop-down">
							<Dropdown drop="left">
								<Dropdown.Toggle className="cust-drop-btn" id="dropdown">
									<svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M2.00002 15.3333C0.987498 15.3333 0.166687 14.5125 0.166687 13.5C0.166687 12.4874 0.987498 11.6666 2.00002 11.6666C3.01254 11.6666 3.83335 12.4874 3.83335 13.5C3.83335 14.5125 3.01254 15.3333 2.00002 15.3333ZM2.00002 9.83329C0.987498 9.83329 0.166687 9.01248 0.166687 7.99996C0.166687 6.98744 0.987498 6.16663 2.00002 6.16663C3.01254 6.16663 3.83335 6.98744 3.83335 7.99996C3.83335 8.48619 3.6402 8.95251 3.29638 9.29632C2.95257 9.64014 2.48625 9.83329 2.00002 9.83329ZM2.00002 4.33329C0.987498 4.33329 0.166687 3.51248 0.166687 2.49996C0.166687 1.48744 0.987498 0.666626 2.00002 0.666626C3.01254 0.666626 3.83335 1.48744 3.83335 2.49996C3.83335 2.98619 3.6402 3.45251 3.29638 3.79632C2.95257 4.14014 2.48625 4.33329 2.00002 4.33329Z" fill="#6C6A81" />
									</svg>
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<ul>
										<li>
											<Dropdown.Item>Delete User</Dropdown.Item>
										</li>
									</ul>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					);
				},
			},
		},
	]);
	const [option, set_option] = useState({
		sizePerPage: 10,
		search: "",
		totalRecord: 0,
		page: 1,
		sort: "createdAt",
		order: "ASC",
	});

	useEffect(() => {
		getAllUser();
	}, []);

	const handleClose = () => {
		setShow(false);
	};

	const handleShow = () => {
		setShow(true);
	};

	let tableCallBack = (option) => {
		set_option(option);
		getAllUser();
	};

	const getAllUser = () => {
		set_loader(true);
		const addUserPromise = new Promise((resolve, reject) => {
			resolve(PostApi(API_Path.getAllUser, option));
		});
		addUserPromise.then((response) => {
			set_loader(false);
			if (response.status === 200) {
				setData(response.data.data);
				set_option({ ...option, totalRecord: response.data.totalRecord });
				toast.success(response.data.message);
			} else {
				toast.error(response.data.message);
				if (response.data.message == "unauthorized access") {
					navigate("/store");
				}
			}
		});
	};

	const handleSubmitUser = (formData) => {
		const addUserPromise = new Promise((resolve, reject) => {
			resolve(PostApi(API_Path.createUser, formData));
		});
		addUserPromise.then((response) => {
			if (response.status === 200) {
				toast.success(response.data.message);
				handleClose();
				getAllUser();
			} else {
				toast.error(response.data.message);
			}
		});
	};

	let errorContainer = (form, field) => {
		return form.touched[field] && form.errors[field] ? <span className="error text-danger">{form.errors[field]}</span> : null;
	};

	let formAttr = (form, field) => ({
		onBlur: form.handleBlur,
		onChange: form.handleChange,
		value: form.values[field],
	});

	return (
		<>
			{loader && (
				<div className="loader loader-dateset-main">
					<Lottie loop animationData={loder_data} play />
				</div>
			)}
			<FrontendLayout PageDetails="user">
				<div className="content-main-section">
					<div className="container-fluid">
						<div className="row">
							<div className="col-12">
								<div className="comn-inr-title d-flex align-items-center">
									<h2>Users List</h2>
									<div className="ms-auto">
										<button onClick={handleShow} type="button" className="btn-smart-comn">
											Add New
										</button>
									</div>
								</div>
							</div>
							<div className="col-12">
								<div className="table-custom-info">
									<RtdDatatable data={data} columns={columns} option={option} tableCallBack={tableCallBack} needPagination={true} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</FrontendLayout>
			<Modal show={show} onHide={handleClose} className="modal-comn-info" size="md" centered>
				<Modal.Header closeButton>
					<Modal.Title>Add New User</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Formik
						enableReinitialize
						initialValues={{
							name: "",
							role: "",
							email: "",
						}}
						validationSchema={Yup.object({
							name: Yup.string().required("Name  is required."),
							email: Yup.string().email().required("Email is required."),
							role: Yup.string().required("Role is required."),
						})}
						onSubmit={(formData, { resetForm }) => {
							handleSubmitUser(formData, resetForm);
						}}
					>
						{(runform) => (
							<form className="row" onSubmit={runform.handleSubmit}>
								<div className="row">
									<div className="col-md-6 mb-3">
										<label className="form-lbl-class mb-2">Name</label>
										<input type="text" className="form-control style-input-class" placeholder="Enter name" name="name" {...formAttr(runform, "name")} />
										{errorContainer(runform, "name")}
									</div>
									<div className="col-md-6 mb-3">
										<label className="form-lbl-class mb-2">Role</label>
										<select className="form-control style-input-class" name="role" {...formAttr(runform, "role")}>
											<option value="">Select Role</option>
											<option value="1">Admin</option>
											<option value="2">User</option>
										</select>
										{errorContainer(runform, "role")}
									</div>
									<div className="col-md-12 mb-3">
										<label className="form-lbl-class mb-2">Email Address</label>
										<input type="email" className="form-control style-input-class" placeholder="Enter email address" name="email" {...formAttr(runform, "email")} />
										{errorContainer(runform, "email")}
									</div>
									<div className="col-12 mt-3 text-center">
										<button type="submit" className="btn-smart-comn px-4">
											Add New User
										</button>
									</div>
								</div>
							</form>
						)}
					</Formik>
				</Modal.Body>
			</Modal>
		</>
	);
}
