import React, { useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import ForgotPassword from "./Pages/Common/ForgotPassword";
import Login from "./Pages/Common/Login";
import ResetPassword from "./Pages/Common/ResetPassword";
import User from "./Pages/User";
import Dashboard from "./Pages/Dashboard";
import Store from "./Pages/Store";
import CreatePassword from "./Pages/Common/CreatePassword";
import DailyReports from "./Pages/DailyReports";
import TaskAssign from "./Pages/TaskAssign";

function RequireAuth({ children, redirectTo }) {
	let isAuthenticated = localStorage.getItem("smart_internal_token");
	return isAuthenticated !== null && isAuthenticated !== undefined && isAuthenticated !== "" ? children : <Navigate to={redirectTo} />;
}

export default function RoutesMain() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" strict element={<Login />} />
				<Route path="/login" strict element={<Login />} />
				<Route path="/forgot-password" strict element={<ForgotPassword />} />
				<Route path="/create-password" strict element={<CreatePassword />} />
				<Route path="/reset-password" strict element={<ResetPassword />} />
				<Route
					path="/dashboard"
					strict
					element={
						<RequireAuth redirectTo="/login">
							<Dashboard />
						</RequireAuth>
					}
				/>
				<Route
					path="/store"
					strict
					element={
						<RequireAuth redirectTo="/login">
							<Store />
						</RequireAuth>
					}
				/>
				<Route
					path="/user"
					strict
					element={
						<RequireAuth redirectTo="/login">
							<User />
						</RequireAuth>
					}
				/>
				<Route
					path="/user-reports"
					strict
					element={
						<RequireAuth redirectTo="/login">
							<User />
						</RequireAuth>
					}
				/>
				<Route
					path="/daily-reports"
					strict
					element={
						<RequireAuth redirectTo="/login">
							<DailyReports />
						</RequireAuth>
					}
				/>
				<Route
					path="/task-assign"
					strict
					element={
						<RequireAuth redirectTo="/login">
							<TaskAssign />
						</RequireAuth>
					}
				/>
			</Routes>
		</BrowserRouter>
	);
}
