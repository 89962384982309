import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import SideImg from "../../images/reset-passsword-image.svg";
import Logo from "../../images/logo.png";
import { API_Path } from "../../const";
import { PostApi } from "../../helper/APIService";

export default function ResetPassword() {
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const [token, settoken] = useState("");

	useEffect(() => {
		settoken(searchParams.get("token"));
	}, []);

	const submitFormData = (formData, resetForm) => {
		delete formData.cpass
		formData.token = token;
		const resetPassword = new Promise((resolve) => {
			resolve(PostApi(API_Path.createPassword, formData));
		});
		resetPassword.then((res) => {
			if (res.status === 200) {
				toast.success(res.data.message);
				resetForm(formData);
				navigate("/login");
			} else {
				toast.error(res.data.message);
			}
		});
	};

	const errorContainer = (form, field) => {
		return form.touched[field] && form.errors[field] ? <span className="error text-danger">{form.errors[field]}</span> : null;
	};

	const formAttr = (form, field) => ({
		onBlur: form.handleBlur,
		onChange: form.handleChange,
		value: form.values[field],
	});

	const showpassword = () => {
		document.getElementById("eye-pwd").classList.toggle("active");
		var x = document.getElementById("toggle-pass");
		if (x.type === "password") {
			x.type = "text";
		} else {
			x.type = "password";
		}
	};

	const showconfirmpassword = () => {
		document.getElementById("eye-confirm-pwd").classList.toggle("active");
		var x = document.getElementById("toggle-confirm-pass");
		if (x.type === "password") {
			x.type = "text";
		} else {
			x.type = "password";
		}
	};

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-xl-4 col-lg-5 p-0 d-lg-block d-none">
						<div className="login-side-img">
							<img src={SideImg} alt="" className="img-fluid" />
						</div>
					</div>
					<div className="col-xl-8 col-lg-7 login-form-side">
						<div className="login-main-area">
							<div className="login-main-part-scroll">
								<div className="login-main-side">
									<div className="m-auto login-main-box user-sign-form white-bx-info">
										<div className="w-100 text-center mb-4">
											<img src={Logo} alt="logo" className="img-fluid" />
										</div>
										<div className="text-center login-text-top pb-2">
											<h1>Reset Password</h1>
											<p>Your new paasword must be different from previous used passowrd.</p>
										</div>
										<Formik
											enableReinitialize
											initialValues={{
												pass: "",
												cpass: "",
											}}
											validationSchema={Yup.object({
												pass: Yup.string().required("Password is required."),
												cpass: Yup.string()
													.when("password", {
														is: (val) => (val && val.length > 0 ? true : false),
														then: Yup.string().oneOf([Yup.ref("password")], "Password must match."),
													})
													.required("Confirmation of Password is required."),
											})}
											onSubmit={(formData, { resetForm }) => {
												submitFormData(formData, resetForm);
											}}
										>
											{(runform) => (
												<form className="row" onSubmit={runform.handleSubmit}>
													<div className="col-12 mb-3">
														<label className="form-lbl-class mb-2">Password</label>
														<bdi className="d-block position-relative password-class">
															<input id="toggle-pass" type="password" className="form-control style-input-class" {...formAttr(runform, "pass")} name="pass" placeholder="Enter your new password" />
															<span id="eye-pwd" className="eye-pwd bg-transparent" onClick={showpassword}>
																<i className="bi bi-eye-slash"></i>
															</span>
														</bdi>
														{errorContainer(runform, "pass")}
													</div>
													<div className="col-12 mb-3">
														<label className="form-lbl-class mb-2">Confirm Password</label>
														<bdi className="d-block position-relative password-class">
															<input id="toggle-confirm-pass" type="password" className="form-control style-input-class" {...formAttr(runform, "cpass")} name="cpass" placeholder="Enter your new confirm password" />

															<span id="eye-confirm-pwd" className="eye-pwd bg-transparent" onClick={showconfirmpassword}>
																<i className="bi bi-eye-slash"></i>
															</span>
														</bdi>
														{errorContainer(runform, "cpass")}
													</div>
													<div className="col-12 mt-3 text-center">
														<button type="submit" className="btn-smart-comn w-100">
															Reset Password
														</button>
													</div>
												</form>
											)}
										</Formik>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
