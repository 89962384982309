import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import SideImg from "../../images/create-password-image.svg";
import Logo from "../../images/logo.png";
import { PostApi } from "../../helper/APIService";
import { API_Path } from "../../const";

export default function CreatePassword() {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [token, settoken] = useState("");
	const [showPassword1, setshowPassword1] = useState("password");
	const [IconPassword1, setIconPassword1] = useState("bi-eye-slash");
	const [showPassword2, setshowPassword2] = useState("password");
	const [IconPassword2, setIconPassword2] = useState("bi-eye-slash");

	useEffect(() => {
		settoken(searchParams.get("token"));
	}, []);

	const submitFormData = (formData, resetForm) => {
		delete formData.cpass
		formData.token = token;
		const resetPassword = new Promise((resolve) => {
			resolve(PostApi(API_Path.createPassword, formData));
		});
		resetPassword.then((res) => {
			if (res.status === 200) {
				toast.success(res.data.message);
				resetForm(formData);
				navigate("/login");
			} else {
				toast.error(res.data.message);
			}
		});
	};

	const errorContainer = (form, field) => {
		return form.touched[field] && form.errors[field] ? <span className="error text-danger">{form.errors[field]}</span> : null;
	};

	const formAttr = (form, field) => ({
		onBlur: form.handleBlur,
		onChange: form.handleChange,
		value: form.values[field],
	});

	const showpassword = () => {
		if (showPassword1 === "password") {
			setshowPassword1("text");
			setIconPassword1("bi-eye");
		} else {
			setshowPassword1("password");
			setIconPassword1("bi-eye-slash");
		}
	};

	const showconfirmpassword = () => {
		if (showPassword2 === "password") {
			setshowPassword2("text");
			setIconPassword2("bi-eye");
		} else {
			setshowPassword2("password");
			setIconPassword2("bi-eye-slash");
		}
	};

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-xl-4 col-lg-5 p-0 d-lg-block d-none">
						<div className="login-side-img">
							<img src={SideImg} alt="" className="img-fluid" />
						</div>
					</div>
					<div className="col-xl-8 col-lg-7 login-form-side">
						<div className="login-main-area">
							<div className="login-main-part-scroll">
								<div className="login-main-side">
									<div className="m-auto login-main-box user-sign-form white-bx-info">
										<div className="w-100 text-center mb-4">
											<img src={Logo} alt="logo" className="img-fluid" />
										</div>
										<div className="text-center login-text-top pb-2">
											<h1>Create Password</h1>
											<p>Your new paasword must be different from previously used passowrd.</p>
										</div>
										<Formik
											enableReinitialize
											initialValues={{
												pass: "",
												cpass: "",
											}}
											validationSchema={Yup.object({
												pass: Yup.string().required("Password is required."),
												cpass: Yup.string()
													.when("password", {
														is: (val) => (val && val.length > 0 ? true : false),
														then: Yup.string().oneOf([Yup.ref("password")], "Password must match."),
													})
													.required("Confirmation of Password is required."),
											})}
											onSubmit={(formData, { resetForm }) => {
												submitFormData(formData, resetForm);
											}}
										>
											{(runform) => (
												<form className="row" onSubmit={runform.handleSubmit}>
													<div className="col-12 mb-3">
														<label className="form-lbl-class mb-2">New Password</label>
														<bdi className="d-block position-relative password-class">
															<input id="toggle-pass" type={showPassword1} className="form-control style-input-class" {...formAttr(runform, "pass")} name="pass" placeholder="Enter your new password" />
															<span id="eye-pwd" className="eye-pwd bg-transparent" onClick={showpassword}>
																<i className={"bi " + IconPassword1} />
															</span>
														</bdi>
														{errorContainer(runform, "pass")}
													</div>
													<div className="col-12 mb-3">
														<label className="form-lbl-class mb-2">Confirm New Password</label>
														<bdi className="d-block position-relative password-class">
															<input id="toggle-confirm-pass" type={showPassword2} className="form-control style-input-class" {...formAttr(runform, "cpass")} name="cpass" placeholder="Enter your new confirm password" />

															<span id="eye-confirm-pwd" className="eye-pwd bg-transparent" onClick={showconfirmpassword}>
																<i className={"bi " + IconPassword2} />
															</span>
														</bdi>
														{errorContainer(runform, "cpass")}
													</div>
													<div className="col-12 mt-3 text-center">
														<button type="submit" className="btn-smart-comn w-100">
															Set Password
														</button>
													</div>
												</form>
											)}
										</Formik>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
